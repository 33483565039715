import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Fab,
  Modal,
  Typography,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import { useState, useRef } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LoadingAnimation from "../../Components/LoadingAnimation";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "@mui/material/Pagination";
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
toast.configure();

const WarrantyEntry = () => {
  const [disableSave, setDisableSave] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    LocalstorageHelper.RemoveUserToken();
  }, 3600000);

  const OPTIONS_LIMIT2 = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });
  const filterOptionsParty = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
    matchFrom: "start",
  });
  //HOLD STATE OF SEARCH INPUT
  const date = new Date();
  const [documentdate, setdocumentdate] = useState(date);
  const [docno, setDocNo] = useState("");
  const [rows, setrows] = useState([]);
  console.log(rows);
  const [mode, setMode] = useState(1);
  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);
  const [docopts, setDocOpts] = useState([]);
  const [docdetails, setDocDetails] = useState(null);
  const [warrantyflag, setwarrantyflag] = useState(true);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [partyopts, setpartyOpts] = useState([]);
  const [partydetails, setPartyDetails] = useState(null);
  // console.log(partydetails);
  const [newModePartNo, setNewModePartNo] = useState("");
  const [partno, setpartno] = useState(null);
  //console.log(partno)
  const [allparts, setallparts] = useState([]);
  const [qty, setqty] = useState(0);
  const [remarks, setremarks] = useState("");
  const [loading, setLoading] = useState(false);
  const quantityRef = useRef(null);
  const partnoRef = useRef(null);
  const newPartNoRef = useRef(null);
  const [itemname, setitemname] = useState("");
  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
  ];
  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  useEffect(() => {
    if (partno) {
      quantityRef.current.focus();
    }
  }, [partno]);
  const handleDeleteClick = (id) => () => {
    if (window.confirm("Are you sure you want to remove the item?") === true) {
      setrows(rows.filter((row) => row.srl !== id));
    }
  };
  const columns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 60,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Dele"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "srl",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 60,
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Part No.",
      width: 200,
      cellClassName: "custom-font-size",
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      width: 200,
      cellClassName: "custom-font-size",
    },
    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "Quantity",
      width: 100,
      cellClassName: "custom-font-size",
      editable: true,
      valueSetter: (params) => {
        const qty = params.value;
        const upd_obj = rows.findIndex((obj) => obj.srl === params.row.srl);
        rows[upd_obj].qty = qty;
        return { ...params.row, qty };
      },
    },
    {
      field: "accept",
      headerClassName: "super-app-theme--header",
      headerName: "AcceptFlag",
      width: 200,
      cellClassName: "custom-font-size",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Accepted", "Rejected", "Pending"],
      valueSetter: (params) => {
        const accept = params.value;
        const subledger = warrantyflag ? "" : partydetails?.partydetails;
        const genremarks = warrantyflag
          ? `${accept}`
          : `${accept} ${subledger}`;
        const upd_obj = rows.findIndex((obj) => obj.srl === params.row.srl);
        rows[upd_obj].accept = accept;
        rows[upd_obj].remarks = genremarks;
        return { ...params.row, accept, remarks: genremarks };
      },
    },

    {
      field: "remarks",
      headerClassName: "super-app-theme--header",
      headerName: "Remarks",
      width: 400,
      cellClassName: "custom-font-size",
      editable: true,
      valueSetter: (params) => {
        const remarks = params.value;
        const upd_obj = rows.findIndex((obj) => obj.srl === params.row.srl);
        rows[upd_obj].remarks = remarks;
        return { ...params.row, remarks };
      },
    },
  ];
  async function getpartyOpts() {
    try {
      const response = await fetch("/warranty/partydata", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  async function getallparts() {
    try {
      setLoading(true);
      const response = await fetch("/warranty/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      console.log(jsonData);
      setallparts(jsonData);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (!isSwitchOn) {
      getallparts();
    }
  }, [isSwitchOn]);
  const handlePartnoKey = async (event) => {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === newPartNoRef.current) {
        const body = { code: newModePartNo };
        // const data = allparts.find((opt) => opt.itemcode === newModePartNo);
        const apicall = await fetch("/allparts/detailfetch", {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const data = await apicall.json();
        if (data.type !== "error") {
          setpartno(data);
          setitemname(data.itemname);
          if (quantityRef.current) {
            quantityRef.current.focus();
          }

          // Prevent the default Tab behavior
          event.preventDefault();
        } else {
          event.preventDefault();
          newPartNoRef.current.focus();
          toast.error("No Part Found!");
          setNewModePartNo("");
        }
      }
    }
  };

  const handleaddrow = () => {
    if (qty < 1) {
      toast.error("Value can't be 0 or negative");
      return;
    }

    const isItemAlreadyAdded = rows.some((row) => row.itemid === partno.itemid);
    if (isItemAlreadyAdded) {
      toast.error("Item Added Already!");
      return;
    }

    setrows((oldRows) => [
      ...oldRows,
      {
        srl: oldRows.length + 1,
        itemid: partno.itemid,
        itemcode: partno.itemcode,
        itemname: partno.itemname,
        qty: qty,
        accept: "Pending", // Default to "Pending" when a new row is added
        remarks: warrantyflag
          ? `${"Pending"} ${remarks}`
          : `${"Pending"} ${partydetails?.partydetails} ${remarks}`,
        partyid: partydetails?.subledgerid || null,
      },
    ]);

    // Reset input fields after adding the row
    setpartno(null);
    setqty(0);
    setNewModePartNo("");
  };
  //insert
  const handleSave = async (e) => {
    e.preventDefault();
    setDisableSave(true);
    try {
      const body = {
        documentdate: documentdate,
        warrantyflag: warrantyflag,
        rows: rows,
        partyid: partydetails?.subledgerid,
      };
      console.log(body);
      const response = await fetch("/warranty/insert", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      // console.log(parseRes);
      if (parseRes.type === "success") {
        toast.success(parseRes.message, {
          autoClose: false,
          onClose: () => window.location.reload(false),
        });
      } else {
        toast.error(`${parseRes.message}`, {
          autoClose: 3000,
        });
        setDisableSave(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  async function getdocnos() {
    try {
      const response = await fetch("/warranty/documentnos", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setDocOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getdocnos();
  }, []);

  //search data
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/warranty/data/${docdetails.headerid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      setrows(jsonData.detaildata);
      setdocumentdate(jsonData.headerdata.documentdate);
      setDocNo(jsonData.headerdata.documentno);
      setwarrantyflag(jsonData.headerdata.warrantyflag);
      setPartyDetails({
        partydetails: jsonData.headerdata.partydetails,
        partydetails2: jsonData.headerdata.partydetails2,
        subledgerid: jsonData.headerdata.subledgerid,
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  //update
  //insert
  const handleUpdate = async (e) => {
    e.preventDefault();
    setDisableUpdate(true);
    try {
      const body = {
        rows: rows,
      };
      //   console.log(body);
      const response = await fetch(`/warranty/update/${docdetails.headerid}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      // console.log(parseRes);
      if (parseRes.type === "success") {
        toast.success(parseRes.message, {
          autoClose: false,
          onClose: () => window.location.reload(false),
        });
      } else {
        toast.error(`${parseRes.message}`, {
          autoClose: 3000,
        });
        setDisableUpdate(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  if (loading) {
    return <LoadingAnimation />;
  }
  return (
    <>
      <div>
        <Sidebar
          Title={"WARRANTY"}
          expandedPanel={"panel2"}
          children={
            <Box
              component="form"
              sx={{
                width: "100%",
              }}
              autoComplete="off"
            >
              <Stack
                spacing={2}
                mb={2}
                direction="row"
                justifyContent={"center"}
              >
                <Link to="/warrantyrtn">
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    sx={{ borderRadius: 10 }}
                  >
                    Go To Warranty Return
                  </Button>
                </Link>
                <Autocomplete
                  disabled={searchdisabled}
                  id="partyno"
                  value={docdetails}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setDocDetails(newValue);
                  }}
                  options={docopts}
                  getOptionLabel={(option) => option.documentno}
                  sx={{
                    width: 500,
                    boxShadow: "2px 2px 6px  #404142",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      placeholder="Search Document"
                    />
                  )}
                />
                <Button
                  disabled={searchbtndisabled}
                  // type="search"
                  variant="contained"
                  key="one"
                  onClick={handleSearch}
                  sx={{
                    borderTopRightRadius: "50px",
                    borderBottomRightRadius: "50px",
                  }}
                >
                  <SearchRoundedIcon />
                  SEARCH
                </Button>
              </Stack>
              <hr />
              <Stack spacing={2} columnGap={1} direction="row" mt={2} ml={4}>
                <TextField
                  id="editmode"
                  required
                  select
                  label="Edit Mode"
                  value={mode}
                  onChange={(e) => {
                    setMode(e.target.value);
                    if (parseInt(e.target.value) === 1) {
                      setSearchDisabled(true);
                      setSearchbtndisabled(true);
                    } else if (parseInt(e.target.value) === 2) {
                      setSearchDisabled(false);
                      setSearchbtndisabled(false);
                    }
                  }}
                  // helperText="Select customer Edit mode"
                  variant="outlined"
                  sx={{
                    width: 150,
                  }}
                  size="small"
                >
                  {allmodes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  disabled
                  id="documentNo"
                  label="Document No"
                  value={docno}
                  type="text"
                  sx={{
                    width: "20%",
                  }}
                  size="small"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Document Date"
                    inputFormat="DD/MM/YYYY"
                    value={documentdate}
                    onChange={(newValue) => {
                      setdocumentdate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        sx={{
                          width: "20%",
                        }}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={parseInt(mode) === 2}
                      checked={warrantyflag}
                      onChange={(e) => setwarrantyflag(e.target.checked)}
                    />
                  }
                  label="Warranty Flag"
                />
                {!warrantyflag && (
                  <Autocomplete
                    id="party"
                    value={partydetails}
                    filterOptions={filterOptionsParty}
                    onChange={(event, newValue) => {
                      setPartyDetails(newValue);
                      // console.log(newValue);
                    }}
                    options={partyopts}
                    getOptionLabel={(option) => option.partydetails}
                    sx={{
                      width: "25% ",
                    }}
                    renderInput={(ordernos) => (
                      <TextField required {...ordernos} placeholder="Party" />
                    )}
                    size="small"
                  />
                )}
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Old Mode</Typography>
                <Switch
                  onChange={handleSwitchChange}
                  checked={!isSwitchOn}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>New Mode</Typography>
              </Stack>
              <Stack spacing={2} columnGap={1} direction="row" mt={2} ml={4}>
                {isSwitchOn && (
                  <TextField
                    id="partno"
                    label="Part No"
                    value={newModePartNo}
                    onChange={(e) => setNewModePartNo(e.target.value)}
                    inputRef={newPartNoRef}
                    onKeyDown={handlePartnoKey}
                    sx={{
                      width: "200px",
                    }}
                    size="small"
                  />
                )}
                {!isSwitchOn && (
                  <Autocomplete
                    id="partno"
                    value={partno}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setpartno(newValue);
                    }}
                    options={allparts}
                    getOptionLabel={(option) => option.partdetails}
                    sx={{
                      width: "30% ",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Part No"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                )}
                <TextField
                  id="qty"
                  label="Quantity"
                  value={qty}
                  onChange={(e) => setqty(e.target.value)}
                  type="number"
                  inputRef={quantityRef}
                  sx={{
                    width: 150,
                  }}
                  size="small"
                />
                {/* {warrantyflag && (
                  <Autocomplete
                    id="accept"
                    value={acceptflag}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setacceptflag(newValue);
                      // console.log(newValue);
                    }}
                    options={acceptflagopts}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      width: 200,
                    }}
                    renderInput={(ordernos) => (
                      <TextField
                        required
                        {...ordernos}
                        placeholder="AcceptFlag"
                      />
                    )}
                    size="small"
                  />
                )} */}

                <TextField
                  id="remarks"
                  label="Remarks"
                  value={remarks}
                  onChange={(e) => setremarks(e.target.value)}
                  multiline
                  sx={{
                    width: 300,
                  }}
                  size="small"
                />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleaddrow}
                  sx={{ borderRadius: 30 }}
                >
                  Add
                </Button>
              </Stack>
              <Stack spacing={2} columnGap={1} direction="row" mt={2} ml={4}>
                <Box
                  sx={{
                    height: 300,
                    width: "1200px",
                    position: "relative",
                    mt: "20px",
                    boxShadow: "2px 6px 15px #5a5a5a",
                    // border: "0.5px solid black",
                    // borderRadius: "5px",
                    "& .super-app-theme--header": {
                      backgroundColor: "#3bb9ff",
                      color: "#000",
                    },
                    "& .custom-font-size": {
                      fontSize: 13, // Adjust the font size as needed
                    },
                  }}
                >
                  <DataGrid
                    sx={{
                      border: "1px solid #000",
                      "& .MuiDataGrid-cell:focus": {
                        outline: "2px solid red", // Change this to the desired color
                        backgroundColor: "gray",
                        color: "white",
                      },
                      "& .MuiDataGrid-cell:focus": {
                        outline: "2px solid red", // Change this to the desired color
                        backgroundColor: "gray",
                        color: "white",
                      },
                    }}
                    rowHeight={35}
                    headerHeight={30}
                    showColumnRightBorder={true}
                    s
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row.srl}
                  />
                </Box>
              </Stack>
              <Stack spacing={2} columnGap={1} direction="row" mt={2} ml={4}>
                {parseInt(mode) === 1 && (
                  <Button
                    disabled={disableSave}
                    onClick={handleSave}
                    size="medium"
                    variant="contained"
                    color="primary"
                  >
                    SAVE
                  </Button>
                )}
                {parseInt(mode) === 2 && (
                  <Button
                    disabled={disableUpdate}
                    onClick={handleUpdate}
                    size="medium"
                    variant="contained"
                    color="primary"
                  >
                    UPDATE
                  </Button>
                )}
              </Stack>
            </Box>
          }
        />
        {redirectToLoginPage()}
      </div>
    </>
  );
};

export default WarrantyEntry;
