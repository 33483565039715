import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Input,
  Modal,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import BillSearch from "@mui/icons-material/Search";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { useEffect } from "react";
import DriveFileMove from "@mui/icons-material/DriveFileMove";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Jobcard = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES AND CHANGE HANDLER

  const [state, setState] = useState({
    jobNo: "",
    regNo: "",
    chassisNo: "",
    engineNo: "",
    jobTypeId: "",
    kmReading: "",
    phoneNo: "",
    customerName: "",
    address1: "",
    address2: "",
    address3: "",
    email: "",
    pincode: "",
  });
  const datenow = new Date();
  const [jobdate, setjobdate] = useState(datenow);
  const [purdate, setpurdate] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      setInsertDisabled(true);
      try {
        const body = {
          state,
          destInput,
          modelInput,
          techInput,
          jobdate,
          purdate,
        };
        const response = await fetch("/insertjobcard", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();

        if (parseRes.type === "success") {
          toast.success(
            `Job No. ${parseRes.jobno} and Subledgercode : ${parseRes.subledgercode} generated successfully!`,
            {
              autoClose: false,
              onClose: () => window.location.reload(false),
            }
          );
        } else {
          toast.error(parseRes.message, { autoClose: 2000 });
          setInsertDisabled(false);
        }
      } catch (err) {
        console.error(err.message);
        setInsertDisabled(false);
      }
    }

    if (insertDisabled) {
      setUpdatedisabled(true);
      try {
        const body = {
          state,
          destInput,
          modelInput,
          techInput,
          jobdate,
          purdate,
        };
        const response = await fetch(`/upjobcard/${searchInput.jobcardid}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        if (data.type === "success") {
          toast.success(data.message, { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          toast.error(data.message);
          setUpdatedisabled(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  //DELETE ITEM

  const handleDelete = async () => {
    try {
      const response = await fetch(`/deletejobcard/${searchInput.jobcardid}`, {
        method: "DELETE",
        headers: { sptoken: userToken },
      });
      const parseRes = await response.json();
      toast.error(parseRes, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/getjobcards", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //  GET Jobtypes Options//
  const [jobtype, setjobtype] = useState([]);
  async function getJobTypes() {
    try {
      const response = await fetch("/jtypesget", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setjobtype(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getJobTypes();
  }, []);

  //  GET MODEL Parameters //
  const [modelopts, setmodelopts] = useState([]);
  async function getModelOpts() {
    try {
      const response = await fetch("/modelsget", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setmodelopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getModelOpts();
  }, []);

  //HOLD STATE OF TECHNICIAN INPUT

  const [techInput, settechInput] = useState(null);

  //  GET TECHNICIAN Parameters //
  const [techopts, settechopts] = useState([]);
  async function getTechOpts() {
    try {
      const response = await fetch("/techsget", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      settechopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getTechOpts();
  }, []);

  //HOLD STATE OF DESTINATION INPUT

  const [destInput, setdestInput] = useState({
    destid: 1,
    destname: "KOLKATA",
    stateid: 1,
  });

  //  GET DESTINATION Parameters //
  const [destopts, setdestopts] = useState([]);
  async function getDestOpts() {
    try {
      const response = await fetch("/destget", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setdestopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getDestOpts();
  }, []);

  //HOLD STATE OF MODEL INPUT

  const [modelInput, setmodelInput] = useState(null);

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //
  const [subledgercode, setsubledgercode] = useState("");
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/getajobcard/${searchInput.jobcardid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      if (jsonData !== "") {
        setState((prevState) => ({
          ...prevState,
          jobNo: jsonData.jobno,
          regNo: jsonData.regno,
          chassisNo: jsonData.chassisno,
          engineNo: jsonData.engineno,
          jobTypeId: jsonData.jobtype,
          kmReading: jsonData.kmreading,
          phoneNo: jsonData.phoneno,
          customerName: jsonData.customername,
          address1: jsonData.address1,
          address2: jsonData.address2,
          address3: jsonData.address3,
          email: jsonData.email,
          pincode: jsonData.pincode,
        }));
        setjobdate(jsonData.jobdate);
        setpurdate(jsonData.purchasedate);
        setmodelInput({
          modelid: `${jsonData.model}`,
          modelname: `${jsonData.modelname}`,
        });
        settechInput({
          techid: `${jsonData.technician}`,
          techname: `${jsonData.techname}`,
        });
        setdestInput({
          destid: `${jsonData.destid}`,
          destname: `${jsonData.destname}`,
          stateid: `${jsonData.stateid}`,
        });
        setsubledgercode(jsonData.subledgercode);
        setUpdatedisabled(false);
        setInsertDisabled(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  /// SET DATA FROM CUSTOMER HISTORY
  const handleCustomerData = async () => {
    try {
      const response = await fetch(`/history/fetch/${state.regNo}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      setState((prevState) => ({
        ...prevState,
        jobNo: "",
        regNo: jsonData.regno,
        chassisNo: jsonData.chassisno,
        engineNo: jsonData.engineno,
        jobTypeId: jsonData.jobtype,
        kmReading: "",
        phoneNo: jsonData.phoneno,
        customerName: jsonData.customername,
        address1: jsonData.address1,
        address2: jsonData.address2,
        address3: jsonData.address3,
        email: jsonData.email,
        pincode: jsonData.pincode,
      }));
      setjobdate(datenow);
      setpurdate(jsonData.purchasedate);
      setdestInput({
        destid: `${jsonData.destid}`,
        destname: `${jsonData.destname}`,
        stateid: `${jsonData.stateid}`,
      });
      setmodelInput({
        modelid: `${jsonData.model}`,
        modelname: `${jsonData.modelname}`,
      });
      settechInput(null);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (!insertDisabled && state.regNo.length > 3) {
      handleCustomerData();
    }
  }, [state.regNo]);

  return (
    <div>
      <Sidebar
        Title={"JOBCARD"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.searchdetails}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Items Here..." />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={2} columnGap={6} direction="row" mt={4} ml={4}>
              <TextField
                disabled
                id="jobNo"
                label="Job No"
                value={state.jobNo}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                required
                id="regNo"
                label="Reg No"
                name="regNo"
                value={state.regNo}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                //required
                id="subledgercode"
                label="Subledger Code"
                name="subledgercode"
                value={subledgercode}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={2} columnGap={6} direction="row" mt={4} ml={4}>
              <TextField
                id="chassisNo"
                label="Chassis No"
                name="chassisNo"
                value={state.chassisNo}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="engineNo"
                label="Engine No"
                name="engineNo"
                value={state.engineNo}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="jobType"
                name="jobTypeId"
                required
                select
                label="Job Type"
                value={state.jobTypeId}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {jobtype.map((option) => (
                  <MenuItem key={option.jtypeid} value={option.jtypeid}>
                    {option.jtypename}
                  </MenuItem>
                ))}
              </TextField>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Job Date"
                  inputFormat="DD/MM/YYYY"
                  value={jobdate}
                  onChange={(newValue) => {
                    setjobdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack
              spacing={2}
              columnGap={6}
              direction="row"
              mt={4}
              ml={4}
              mb={4}
            >
              <Autocomplete
                id="modelsearch"
                value={modelInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setmodelInput(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.modelid) === parseInt(value.modelid)
                }
                options={modelopts}
                getOptionLabel={(option) => option.modelname}
                sx={{
                  width: "20% ",
                }}
                renderInput={(partyopts) => (
                  <TextField
                    required
                    {...partyopts}
                    placeholder="Select Model"
                  />
                )}
                size="small"
              />

              <Autocomplete
                id="techniciansearch"
                value={techInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  settechInput(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.techid) === parseInt(value.techid)
                }
                options={techopts}
                getOptionLabel={(option) => option.techname}
                sx={{
                  width: "20% ",
                }}
                renderInput={(partyopts) => (
                  <TextField
                    required
                    {...partyopts}
                    placeholder="Select Technician"
                  />
                )}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Purchase Date"
                  inputFormat="DD/MM/YYYY"
                  value={purdate}
                  onChange={(newValue) => {
                    setpurdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                required
                id="kmReading"
                label="KM Reading"
                name="kmReading"
                value={state.kmReading}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>

            <hr />
            <Stack spacing={2} columnGap={6} direction="row" mt={4} ml={4}>
              <TextField
                required
                id="phoneNo"
                label="Phone No"
                name="phoneNo"
                value={state.phoneNo}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                required
                id="customerName"
                label="Customer Name"
                name="customerName"
                value={state.customerName}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />

              <Autocomplete
                id="destination"
                value={destInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setdestInput(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.destid) === parseInt(value.destid)
                }
                options={destopts}
                getOptionLabel={(option) => option.destname}
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Destination" />
                )}
                size="small"
              />

              <TextField
                required
                id="address1"
                label="Address 1"
                name="address1"
                value={state.address1}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={2} columnGap={6} direction="row" mt={4} ml={4}>
              <TextField
                id="address2"
                label="Address 2"
                name="address2"
                value={state.address2}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address3"
                label="Address 3"
                name="address3"
                value={state.address3}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />

              <TextField
                id="email"
                label="Email"
                name="email"
                value={state.email}
                onChange={handleChange}
                type="email"
                sx={{
                  width: "20%",
                }}
                size="small"
              />

              <TextField
                required
                id="pinCode"
                label="Pin Code"
                name="pincode"
                value={state.pincode}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>

            <Stack spacing={2} columnGap={6} direction="row" mt={12} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    jobNo: "",
                    regNo: "",
                    chassisNo: "",
                    engineNo: "",
                    jobTypeId: "",
                    kmReading: "",
                    phoneNo: "",
                    customerName: "",
                    address1: "",
                    address2: "",
                    address3: "",
                    email: "",
                    pincode: "",
                  }));
                  setjobdate(null);
                  setpurdate(null);
                  setmodelInput(null);
                  settechInput(null);
                  setdestInput(null);
                  setUpdatedisabled(true);
                  setInsertDisabled(false);
                  setSearchInput(null);
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                onClick={handleDelete}
              >
                DELETE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Jobcard;
