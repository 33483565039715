import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Fab,
  Modal,
  Typography,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import { useState, useRef } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LoadingAnimation from "../../Components/LoadingAnimation";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "@mui/material/Pagination";
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { type } from "@testing-library/user-event/dist/type";
toast.configure();

const WarrantyReturn = () => {
  const [disableSave, setDisableSave] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    LocalstorageHelper.RemoveUserToken();
  }, 3600000);

  const OPTIONS_LIMIT2 = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });

  const date = new Date();
  const [documentdate, setdocumentdate] = useState(date);
  const [docno, setDocNo] = useState("");
  const [rows, setrows] = useState([]);
  console.log("Rows", rows);
  const handleDeleteClick = (id) => () => {
    if (window.confirm("Are you sure you want to remove the item?") === true) {
      setrows(rows.filter((row) => row.srl !== id));
    }
  };
  const columns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 60,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Dele"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "srl",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 60,
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Part No.",
      width: 200,
      cellClassName: "custom-font-size",
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      width: 200,
      cellClassName: "custom-font-size",
    },
    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "WarrantyQty",
      width: 100,
      cellClassName: "custom-font-size",
    },
    {
      field: "rtnqty",
      headerClassName: "super-app-theme--header",
      headerName: "ReturnQty",
      width: 100,
      cellClassName: "custom-font-size",
      type: "number",
      editable: true,
      valueSetter: (params) => {
        const rtnqty = params.value;
        const balance = params.row.balance;
        if (rtnqty > balance) {
          toast.error("Return quantity cannot exceed balance!"); // Triggering the error toast
          return { ...params.row }; // Return the original row without updating if validation fails
        }
        const upd_obj = rows.findIndex((obj) => obj.srl === params.row.srl);
        rows[upd_obj].rtnqty = rtnqty;
        return { ...params.row, rtnqty };
      },
    },
    {
      field: "balance",
      headerClassName: "super-app-theme--header",
      headerName: "Balance",
      width: 100,
      cellClassName: "custom-font-size",
      //editable: true,
      valueSetter: (params) => {
        const balance = params.value;
        const upd_obj = rows.findIndex((obj) => obj.srl === params.row.srl);
        rows[upd_obj].balance = balance;
        return { ...params.row, balance };
      },
    },
    {
      field: "settledbyamt",
      headerClassName: "super-app-theme--header",
      headerName: "SettledByAmt",
      width: 130,
      cellClassName: "custom-font-size",
      editable: true,
      type: "boolean", // Change type to boolean
      valueSetter: (params) => {
        const settledbyamt = params.value; // This will now hold true or false
        const upd_obj = rows.findIndex((obj) => obj.srl === params.row.srl);
        rows[upd_obj].settledbyamt = settledbyamt;
        return { ...params.row, settledbyamt };
      },
    },
  ];
  const [mode, setMode] = useState(1);
  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
  ];
  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);
  const [docopts, setDocOpts] = useState([]);
  const [docdetails, setDocDetails] = useState(null);
  const [warrantyno, setwarrantyno] = useState(null);
  const [warrantyopts, setwarrantyopts] = useState([]);

  //get document no from warranty
  async function getdocnos() {
    try {
      const response = await fetch("/warranty/rtn/documentnos", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setwarrantyopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getdocnos();
  }, []);
  //get data
  async function getdocData() {
    try {
      const response = await fetch(
        `/warranty/data/rtn/${warrantyno.headerid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setrows(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getdocData();
  }, [warrantyno]);

  //insert
  const handleSave = async (e) => {
    e.preventDefault();
    setDisableSave(true);
    try {
      const body = {
        documentdate: documentdate,
        wheaderid: warrantyno.headerid,
        rows: rows,
      };
      console.log(body);
      const response = await fetch("/warranty/rtn/insert", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      // console.log(parseRes);
      if (parseRes.type === "success") {
        toast.success(parseRes.message, {
          autoClose: false,
          onClose: () => window.location.reload(false),
        });
      } else {
        toast.error(`${parseRes.message}`, {
          autoClose: 3000,
        });
        setDisableSave(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"WARRANTY RETURN"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={2} mb={2} direction="row" justifyContent={"center"}>
              <Link to="/warranty">
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  sx={{ borderRadius: 10 }}
                >
                  Back To Warranty
                </Button>
              </Link>
            </Stack>
            <hr />
            <Stack spacing={2} columnGap={1} direction="row" mt={2} ml={4}>
              {/* <TextField
                id="editmode"
                required
                select
                label="Edit Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (parseInt(e.target.value) === 1) {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                  } else if (parseInt(e.target.value) === 2) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                  }
                }}
                // helperText="Select customer Edit mode"
                variant="outlined"
                sx={{
                  width: 150,
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                disabled
                id="documentNo"
                label="Return Document No"
                value={docno}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Return Document Date"
                  inputFormat="DD/MM/YYYY"
                  value={documentdate}
                  onChange={(newValue) => {
                    setdocumentdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <Autocomplete
                id="warrantyDamageno"
                value={warrantyno}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setwarrantyno(newValue);
                  // console.log(newValue);
                }}
                options={warrantyopts}
                getOptionLabel={(option) => option.documentno}
                sx={{
                  width: "25% ",
                }}
                renderInput={(ordernos) => (
                  <TextField required {...ordernos} placeholder="Warranty No" />
                )}
                size="small"
              />
            </Stack>
            <Stack
              spacing={2}
              columnGap={1}
              direction="row"
              mt={2}
              ml={4}
            ></Stack>
            <Stack spacing={2} columnGap={1} direction="row" mt={2} ml={4}>
              <Box
                sx={{
                  height: 300,
                  width: "950px",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13, // Adjust the font size as needed
                  },
                }}
              >
                <DataGrid
                  sx={{
                    border: "1px solid #000",
                    "& .MuiDataGrid-cell:focus": {
                      outline: "2px solid red", // Change this to the desired color
                      backgroundColor: "gray",
                      color: "white",
                    },
                    "& .MuiDataGrid-cell:focus": {
                      outline: "2px solid red", // Change this to the desired color
                      backgroundColor: "gray",
                      color: "white",
                    },
                  }}
                  rowHeight={35}
                  headerHeight={30}
                  showColumnRightBorder={true}
                  s
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.srl}
                />
              </Box>
            </Stack>
            <Stack spacing={2} columnGap={1} direction="row" mt={2} ml={4}>
              {parseInt(mode) === 1 && (
                <Button
                  disabled={disableSave}
                  onClick={handleSave}
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  SAVE
                </Button>
              )}
              {parseInt(mode) === 2 && (
                <Button
                  disabled={disableUpdate}
                  //onClick={handleUpdate}
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  UPDATE
                </Button>
              )}
            </Stack>
          </Box>
        }
      />
      {redirectToLoginPage()}
    </div>
  );
};

export default WarrantyReturn;
