import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Fab,
  Tooltip,
  Typography,
  Switch,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ImportExcel from "./ImportExcel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PickList from "./PickList";
import Draggable from "react-draggable";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import LoadingAnimation from "../../Components/LoadingAnimation";
import PicklistView from "./PicklistView";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const CustomerOrder = () => {
  const [disableSave, setDisableSave] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  //alert dialoge
  const [alertopen, setalertOpen] = useState(false);

  const handledalertOpen = () => {
    setalertOpen(true);
  };

  const handledalertClose = () => {
    setalertOpen(false);
  };

  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   LocalstorageHelper.RemoveUserToken();
  // }, 3600000);
  // --------- SHORTCUT KEY FOR ALT ITEM AND AUTO FIRE ON TAB -------- //
  const quantityRef = useRef(null);
  const partnoRef = useRef(null);
  const newPartNoRef = useRef(null);
  const handlePartnoKey = async (event) => {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === newPartNoRef.current) {
        const body = { code: newModePartNo };
        // const data = allparts.find((opt) => opt.itemcode === newModePartNo);
        const apicall = await fetch("/allparts/detailfetch", {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const data = await apicall.json();
        if (data.type !== "error") {
          setpartno(data);
          setstock(data.purchaseqty);
          setDelItem(data.deleteditem);
          setaltFlag(data.altflag);
          setmrp(data.mrp);
          setitemname(data.itemname);
          if (quantityRef.current) {
            quantityRef.current.focus();
          }

          // Prevent the default Tab behavior
          event.preventDefault();
        } else {
          event.preventDefault();
          newPartNoRef.current.focus();
          toast.error("No Part Found!");
          setNewModePartNo("");
        }
      }
    }
  };
  async function handleKeyDown(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === quantityRef.current) {
        handleaddrow();
        partnoRef.current.focus();
        newPartNoRef.current.focus();
        event.preventDefault();
      }
    }
    if (event.code === "F2") {
      if (event.target === quantityRef.current) {
        try {
          const response = await fetch(`/altitemstock/${partno.itemid}`, {
            method: "GET",
            headers: {
              sptoken: userToken,
            },
          });
          const jsonData = await response.json();
          setaltparts(jsonData);
          event.preventDefault();
          //console.log(altparts);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  }
  // const altItemRef = useRef(null);
  // async function handleAltShortcut(event) {
  //   if (event.code === "F2") {
  //     if (event.target === altItemRef.current) {
  //       try {
  //         const response = await fetch(`/altitemstock/${partno.itemid}`, {
  //           method: "GET",
  //           headers: {
  //             sptoken: userToken,
  //           },
  //         });
  //         const jsonData = await response.json();
  //         setaltparts(jsonData);
  //         //console.log(altparts);
  //       } catch (err) {
  //         console.log(err.message);
  //       }
  //     }
  //   }
  // }
  function handleSwitch(opt) {
    setpartno({
      deleteditem: opt.deleteditem,
      gstrate: opt.gstrate,
      itemid: opt.alternateitemid,
      mrp: opt.mrp,
      partdetails: opt.itemcode + "-" + opt.itemname,
      purchaseqty: opt.closingqty,
      rackname: opt.rackname,
      salerate: opt.salerate,
      spcldiscount: opt.spcldiscount,
      groupid: opt.groupid,
      discpers: opt.discpers,
    });
    setstock(opt.closingqty);
    if (isSwitchOn) {
      setNewModePartNo(opt.itemcode);
    }
  }
  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  const [canceldisplay, setcanceldisplay] = useState("none");
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 20;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const filterOptionsParty = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
    matchFrom: "start",
  });
  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //
  const [ordernos, setordernos] = useState([]);
  //console.log(searchInput);
  const handleSearch = async (e) => {
    e.preventDefault();
    let jsonData;
    try {
      if (parseInt(mode) === 2) {
        const response = await fetch(
          `/partydatadetails/${searchInput.partyid}`,
          {
            method: "GET",
            headers: {
              sptoken: userToken,
            },
          }
        );
        jsonData = await response.json();
      } else {
        const response = await fetch(
          `/view/partydatadetails/${searchInput.partyid}`,
          {
            method: "GET",
            headers: {
              sptoken: userToken,
            },
          }
        );
        jsonData = await response.json();
      }

      const newPartyValue = partyopts.find(
        (opt) => opt.subledgerid === searchInput.partyid
      );
      setPartyValue(newPartyValue);
      setsalesmanname(jsonData[0].salesmanname);
      setaddress1(jsonData[0].address1);
      setaddress2(jsonData[0].address2);
      setaddress3(jsonData[0].address3);
      setpanno(jsonData[0].panno);
      setgstno(jsonData[0].gstno);
      setdiscrate(jsonData[0].partsdiscrate);
      setordernos(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET BILL Search Parameters //
  const [partyopts, setpartyOpts] = useState([]);
  async function getpartyOpts() {
    try {
      const response = await fetch("/partydata", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  //HOLD STATE OF PARTY SEARCH INPUT

  const [billsearch, setBillSearch] = useState(null);
  const [outstandingdata, setoutstandingdata] = useState("");
  //SET PARTY SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
    //matchFrom: "start",
  });
  const [isMSwitchOn, setMSwitchOn] = useState(false);
  const handleMSwitchChange = () => {
    setMSwitchOn(!isMSwitchOn);
  };
  //   CLICKING ON PARTY SEARCH EXECUTES THE FOLLOWING    //
  const handleBillSearch = async (e) => {
    e.preventDefault();
    if(billsearch===null){
      toast.error("Select Party!!")
      return
    }
    try {
      const response = await fetch(`/partydata/${billsearch.subledgerid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      //console.log(jsonData);
      setpartyname(jsonData.partydata[0].subledgername);
      setaddress1(jsonData.partydata[0].address1);
      setaddress2(jsonData.partydata[0].address2);
      setaddress3(jsonData.partydata[0].address3);
      setsalesmanname(jsonData.partydata[0].salesmanname);
      setpanno(jsonData.partydata[0].panno);
      setgstno(jsonData.partydata[0].gstno);
      setdiscrate(jsonData.partydata[0].partsdiscrate);
      setspecialdis(jsonData.partydata[0].specialdis);
      setLubDiscRate(jsonData.partydata[0].lubdiscrate);
      setSyntheticDiscRate(jsonData.partydata[0].syntheticdiscrate);
      setSemiSyntheticDiscRate(jsonData.partydata[0].semisyntheticdiscrate);
      setRaceProDiscRate(jsonData.partydata[0].raceprodiscrate);
      setenginepartspia(jsonData.partydata[0].enginepartspia);
      setoutstandingdata(jsonData.outstanding);
    } catch (err) {
      console.log(err.message);
    }
  };

  //////////**************************** STATES OF TEXT FIELDS ************************************\\\\\\
  const date = new Date();
  const [orderdate, setorderdate] = useState(date);
  const [partyname, setpartyname] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [address3, setaddress3] = useState("");
  const [panno, setpanno] = useState("");
  const [gstno, setgstno] = useState("");
  const [salesmanname, setsalesmanname] = useState("");
  const [discrate, setdiscrate] = useState(0);
  const [specialdis, setspecialdis] = useState(0);
  const [lubdiscrate, setLubDiscRate] = useState(0);
  const [batterydiscrate, setBatteryDiscRate] = useState(0);
  const [syntheticdiscrate, setSyntheticDiscRate] = useState(0);
  const [semisyntheticdiscrate, setSemiSyntheticDiscRate] = useState(0);
  const [raceprodiscrate, setRaceProDiscRate] = useState(0);
  const [enginepartspia, setenginepartspia] = useState(0);
  const [searchby, setsearchby] = useState(1);
  const [newModePartNo, setNewModePartNo] = useState("");
  const [mrp, setmrp] = useState(0);
  const [itemname, setitemname] = useState("");
  //Grid Loader status
  const handleDeleteClick = (id) => () => {
    if (parseInt(mode) === 3) {
      return toast.error("Not Allowed!!", { toastId: 1 });
    }
    if (parseInt(mode) === 1) {
      setrows(rows.filter((row) => row.itemid !== id));
    } else {
      setemodearray(emodearray.filter((row) => row.itemid !== id));
    }
  };
  //------------------ ALTERNATE PART CHECK ----------------//
  const [altparts, setaltparts] = useState([]);

  //GRID TABLE COLUMN
  const columns = [
    {
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 20,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      type: "number",
      textAlign: "left",
      width: 30,
    },
    {
      field: "partno",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      cellClassName: "custom-font-size",
      width: 350,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    // {
    //   field: "del",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Del",
    //   width: 50,
    // },
    {
      field: "stkqty",
      headerClassName: "super-app-theme--header",
      headerName: "StkQty",
      width: 80,
      type: "number",
    },
    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack",
      width: 70,
      type: "number",
    },
    {
      field: "ordqty",
      headerClassName: "super-app-theme--header",
      headerName: "OrdQty",
      width: 80,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.ordqty;
      },
      valueSetter: (params) => {
        const ordqty = params.value;
        const saleqty =
          parseFloat(params.row.stkqty) > parseFloat(params.value)
            ? params.value
            : params.row.stkqty;
        const losqty = parseFloat(params.value - saleqty);
        const value = parseFloat(parseFloat(ordqty) * params.row.price).toFixed(2);
        const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
          2
        );
        const taxable = value - disamt;
        const gstamt = parseFloat((taxable * params.row.gstpers) / 100).toFixed(
          2
        );
        const netamt = parseFloat(
          parseFloat(taxable) + parseFloat(gstamt)
        ).toFixed(2);
        if (parseInt(mode) === 1) {
          const upd_obj = rows.findIndex(
            (obj) => obj.itemid === params.row.itemid
          );
          rows[upd_obj].ordqty = ordqty;
          rows[upd_obj].saleqty = saleqty;
          rows[upd_obj].losqty = losqty;
          rows[upd_obj].value = value;
          rows[upd_obj].disamt = disamt;
          rows[upd_obj].taxable = taxable;
          rows[upd_obj].gstamt = gstamt;
          rows[upd_obj].netamt = netamt;
          return {
            ...params.row,
            ordqty,
            saleqty,
            value,
            losqty,
            disamt,
            taxable,
            gstamt,
            netamt,
          };
        } else {
          const upd_obj = emodearray.findIndex(
            (obj) => obj.itemid === params.row.itemid
          );
          emodearray[upd_obj].ordqty = ordqty;
          emodearray[upd_obj].saleqty = saleqty;
          emodearray[upd_obj].losqty = losqty;
          emodearray[upd_obj].value = value;
          emodearray[upd_obj].disamt = disamt;
          emodearray[upd_obj].taxable = taxable;
          emodearray[upd_obj].gstamt = gstamt;
          emodearray[upd_obj].netamt = netamt;
          return {
            ...params.row,
            ordqty,
            saleqty,
            value,
            disamt,
            losqty,
            taxable,
            gstamt,
            netamt,
          };
        }
      },
    },
    {
      field: "losqty",
      headerClassName: "super-app-theme--header",
      headerName: "LosQty",
      width: 80,
      type: "number",
    },
    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "SaleQty",
      type: "number",
      width: 80,
      editable: false,
      valueGetter: (params) => {
        return params.row.saleqty;
      },
      // valueSetter: (params) => {
      //   if (params.value > params.row.ordqty) {
      //     toast.error("Cannot be greater than Order Quantity!", {
      //       toastId: "error1",
      //     });
      //   } else if (params.value > params.row.stkqty) {
      //     toast.error("Cannot be greater than Stock!", {
      //       toastId: "error1",
      //     });
      //   } else if (params.value < 0) {
      //     toast.error("Can't be negative", {
      //       toastId: "error1",
      //     });
      //   } else {
      //     const saleqty = params.value;
      //     const losqty = parseFloat(params.row.ordqty - params.value);
      //     const value = parseFloat(params.value * params.row.price).toFixed(2);
      //     const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
      //       2
      //     );
      //     const taxable = value - disamt;
      //     const gstamt = parseFloat(
      //       (taxable * params.row.gstpers) / 100
      //     ).toFixed(2);
      //     const netamt = parseFloat(
      //       parseFloat(taxable) + parseFloat(gstamt)
      //     ).toFixed(2);
      //     if (parseInt(mode) === 1) {
      //       const upd_obj = rows.findIndex(
      //         (obj) => obj.itemid === params.row.itemid
      //       );
      //       rows[upd_obj].saleqty = saleqty;
      //       rows[upd_obj].losqty = losqty;
      //       rows[upd_obj].value = value;
      //       rows[upd_obj].disamt = disamt;
      //       rows[upd_obj].taxable = taxable;
      //       rows[upd_obj].gstamt = gstamt;
      //       rows[upd_obj].netamt = netamt;
      //       return {
      //         ...params.row,
      //         saleqty,
      //         value,
      //         losqty,
      //         disamt,
      //         taxable,
      //         gstamt,
      //         netamt,
      //       };
      //     } else {
      //       const upd_obj = emodearray.findIndex(
      //         (obj) => obj.itemid === params.row.itemid
      //       );
      //       emodearray[upd_obj].saleqty = saleqty;
      //       emodearray[upd_obj].losqty = losqty;
      //       emodearray[upd_obj].value = value;
      //       emodearray[upd_obj].disamt = disamt;
      //       emodearray[upd_obj].taxable = taxable;
      //       emodearray[upd_obj].gstamt = gstamt;
      //       emodearray[upd_obj].netamt = netamt;
      //       return {
      //         ...params.row,
      //         saleqty,
      //         value,
      //         disamt,
      //         losqty,
      //         taxable,
      //         gstamt,
      //         netamt,
      //       };
      //     }
      //   }
      // },
    },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      width: 80,
      type: "number",
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "Price",
      width: 80,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "dispers",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      width: 80,
      type: "number",
      // editable: fa,
      // valueGetter: (params) => {
      //   return params.row.dispers;
      // },
      // valueSetter: (params) => {
      //     const dispers = params.value;
      //     const disamt = parseFloat(
      //       (params.value * params.row.value) / 100
      //     ).toFixed(2);
      //     const taxable = parseFloat(params.row.value - disamt).toFixed(2);
      //     const gstamt = parseFloat(
      //       (taxable * params.row.gstpers) / 100
      //     ).toFixed(2);
      //     const netamt = parseFloat(gstamt + taxable).toFixed(2);
      //     if (parseInt(mode) === 1) {
      //       const upd_obj = rows.findIndex(
      //         (obj) => obj.itemid === params.row.itemid
      //       );
      //       rows[upd_obj].dispers = dispers;
      //       rows[upd_obj].disamt = disamt;
      //       rows[upd_obj].taxable = taxable;
      //       rows[upd_obj].gstamt = gstamt;
      //       rows[upd_obj].netamt = netamt;
      //       return {
      //         ...params.row,
      //         dispers,
      //         disamt,
      //         taxable,
      //         gstamt,
      //         netamt,
      //       };
      //     } else {
      //       const upd_obj = emodearray.findIndex(
      //         (obj) => obj.itemid === params.row.itemid
      //       );
      //       emodearray[upd_obj].dispers = dispers;
      //       emodearray[upd_obj].disamt = disamt;
      //       emodearray[upd_obj].taxable = taxable;
      //       emodearray[upd_obj].gstamt = gstamt;
      //       emodearray[upd_obj].netamt = netamt;
      //       return {
      //         ...params.row,
      //         dispers,
      //         disamt,
      //         taxable,
      //         gstamt,
      //         netamt,
      //       };
      //     }

      // },
    },
    {
      field: "disamt",
      headerClassName: "super-app-theme--header",
      headerName: "DisAmt",
      width: 100,
      type: "number",
    },
    {
      field: "taxable",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable",
      width: 80,
      type: "number",
    },
    {
      field: "gstpers",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 60,
      type: "number",
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GST Amt",
      width: 120,
      type: "number",
    },
    {
      field: "netamt",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 120,
      type: "number",
    },
  ];
  const [rows, setrows] = useState([]);

  // ADD ROW ON CLICK

  const handleaddrow = () => {
    if (itemqty < 1) {
      toast.error("Value can't be 0 or negative");
    } else {
      if (
        parseInt(mode) === 1
          ? rows.map((e) => e.itemid).includes(partno.itemid)
          : emodearray.map((e) => e.itemid).includes(partno.itemid)
      ) {
        toast.error("Item Added Already!");
      } else {
        if (parseInt(mode) === 1) {
          ///DISCOUNT CALCULATION -- 1. CHECK ITEM GROUP
          const groupdisc = partno.discpers;
          // const finaldiscountrate =
          //   partno.spcldiscount === true
          //     ? parseFloat(discrate) + parseFloat(specialdis)
          //     : parseFloat(discrate);
          const groupDiscounts = {
            12: parseFloat(groupdisc), // ENGINE OIL
            13: parseFloat(groupdisc), // ESSENTIAL OIL
            14: parseFloat(groupdisc), // ENF SPCL 20
            15: parseFloat(groupdisc), // ENF SPCL 25
            16: parseFloat(groupdisc) + parseFloat(enginepartspia), // PIAJIO ENGINE PARTS
            2: parseFloat(groupdisc) + parseFloat(lubdiscrate), // LUBRICANT
            3: parseFloat(groupdisc) + parseFloat(batterydiscrate), // BATTERY
            9: parseFloat(groupdisc) + parseFloat(raceprodiscrate), // RACE PRO
            10: parseFloat(groupdisc) + parseFloat(semisyntheticdiscrate), // SEMI SYNTHETIC
            11: parseFloat(groupdisc) + parseFloat(syntheticdiscrate), // SYNTHETIC
            // Add more groups here as needed
          };

          const finaldiscountrate = partno.spcldiscount
            ? parseFloat(specialdis) +
              (groupDiscounts[parseInt(partno.groupid)] || parseFloat(discrate))
            : groupDiscounts[parseInt(partno.groupid)] || parseFloat(discrate);
          const discountamount =
            // (parseInt(itemqty) *
            //   parseFloat(partno.salerate) *
            //   parseFloat(finaldiscountrate)) /
            // 100;
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(finaldiscountrate)) /
                100
              : (parseInt(itemqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(finaldiscountrate)) /
                100;

          const valueamount =
            // parseInt(itemqty) * parseFloat(partno.salerate);
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(partno.salerate)
              : parseInt(itemqty) * parseFloat(partno.salerate);
          const taxableamount = parseFloat(
            valueamount - discountamount
          ).toFixed(2);
          const gstamount = parseFloat(
            (taxableamount * partno.gstrate) / 100
          ).toFixed(2);
          const netamt = parseFloat(
            parseFloat(taxableamount) + parseFloat(gstamount)
          ).toFixed(2);
          setrows((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partno: partno.partdetails,
              mrp: partno.mrp,
              gstpers: partno.gstrate,
              price: partno.salerate,
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: parseInt(itemqty),
              losqty:
                // parseFloat(0),
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                // parseFloat(0),
                itemqty - partno.purchaseqty > 0
                  ? parseFloat(
                      (parseFloat(itemqty) - parseFloat(partno.purchaseqty)) *
                        (parseFloat(partno.mrp) -
                          (partno.mrp * finaldiscountrate) / 100)
                    ).toFixed(2)
                  : 0,
              saleqty:
                // parseInt(itemqty),
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              dispers: parseFloat(finaldiscountrate).toFixed(2),
              value: parseFloat(valueamount).toFixed(2),
              disamt: parseFloat(discountamount).toFixed(2),
              taxable: parseFloat(taxableamount).toFixed(2),
              gstamt: parseFloat(gstamount).toFixed(2),
              netamt: netamt,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        } else {
          // const finaldiscountrate =
          //   partno.spcldiscount === true
          //     ? parseFloat(searchInput.partsdiscrate) +
          //       parseFloat(searchInput.specialdis)
          //     : parseFloat(searchInput.partsdiscrate);

          const groupdisc = partno.discpers;
          // const finaldiscountrate =
          //   partno.spcldiscount === true
          //     ? parseFloat(discrate) + parseFloat(specialdis)
          //     : parseFloat(discrate);
          const groupDiscounts = {
            12: parseFloat(groupdisc), // ENGINE OIL
            13: parseFloat(groupdisc), // ESSENTIAL OIL
            14: parseFloat(groupdisc), // ENF SPCL 20
            15: parseFloat(groupdisc), // ENF SPCL 25
            16: parseFloat(groupdisc) + parseFloat(enginepartspia), // PIAJIO ENGINE PARTS
            2: parseFloat(groupdisc) + parseFloat(lubdiscrate), // LUBRICANT
            3: parseFloat(groupdisc) + parseFloat(batterydiscrate), // BATTERY
            9: parseFloat(groupdisc) + parseFloat(raceprodiscrate), // RACE PRO
            10: parseFloat(groupdisc) + parseFloat(semisyntheticdiscrate), // SEMI SYNTHETIC
            11: parseFloat(groupdisc) + parseFloat(syntheticdiscrate), // SYNTHETIC
            // Add more groups here as needed
          };

          const finaldiscountrate = partno.spcldiscount
            ? parseFloat(specialdis) +
              (groupDiscounts[parseInt(partno.groupid)] || parseFloat(discrate))
            : groupDiscounts[parseInt(partno.groupid)] || parseFloat(discrate);
          const discountamount =
            // (parseInt(itemqty) *
            //   parseFloat(partno.salerate) *
            //   parseFloat(finaldiscountrate)) /
            // 100;
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(finaldiscountrate)) /
                100
              : (parseInt(itemqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(finaldiscountrate)) /
                100;
          const valueamount =
            // parseInt(itemqty) * parseFloat(partno.salerate);
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(partno.salerate)
              : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
          const taxableamount = parseFloat(
            valueamount - discountamount
          ).toFixed(2);
          const gstamount = parseFloat(
            (taxableamount * partno.gstrate) / 100
          ).toFixed(2);
          const netamt = parseFloat(
            parseFloat(taxableamount) + parseFloat(gstamount)
          ).toFixed(2);
          setemodearray((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partno: partno.partdetails,
              mrp: partno.mrp,
              gstpers: partno.gstrate,
              price: partno.salerate,
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: parseInt(itemqty),
              losqty:
                // parseFloat(0),
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                // parseFloat(0),
                itemqty - partno.purchaseqty > 0
                  ? parseFloat(
                      (parseFloat(itemqty) - parseFloat(partno.purchaseqty)) *
                        (parseFloat(partno.mrp) -
                          (partno.mrp * finaldiscountrate) / 100)
                    ).toFixed(2)
                  : 0,
              saleqty:
                // parseInt(itemqty),
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              dispers: finaldiscountrate,
              value: parseFloat(valueamount).toFixed(2),
              disamt: parseFloat(discountamount).toFixed(2),
              taxable: parseFloat(taxableamount).toFixed(2),
              gstamt: parseFloat(gstamount).toFixed(2),
              netamt: netamt,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        }
      }
    }
  };

  // STORE GRID DETAILS

  const [griddata, setgriddata] = useState([]);
  const griddataarray = Object.values(griddata);
  // console.log(griddataarray);
  // Search parts
  const [partno, setpartno] = useState(null);
  console.log("PartNo", partno);
  const [stock, setstock] = useState(0);
  const [delItem, setDelItem] = useState("");
  const [altFlag, setaltFlag] = useState("");
  const [itemqty, setitemqty] = useState(0);
  const [allparts, setallparts] = useState([]);
  //switch
  const [isSwitchOn, setSwitchOn] = useState(true);
  const handleSwitchChange = () => {
    setSwitchOn(!isSwitchOn);
  };
  // console.log(allparts);
  useEffect(() => {
    if (partno) {
      quantityRef.current.focus();
    }
  }, [partno]);

  async function getallparts() {
    try {
      setLoading(true);
      const response = await fetch("/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (!isSwitchOn) {
      getallparts();
    }
  }, [isSwitchOn]);

  /// TOTAL VALUE STATES FOR HEADER TABLE

  const [totallossqty, setTotallossqty] = useState(0);
  const [totlossval, setTotlossval] = useState(0);
  const [totmiscamt, setTotmiscamt] = useState(0);
  const [totgstamt, setTotgstamt] = useState(0);
  const [totnetamt, setTotnetamt] = useState(0);
  const [totvalue, setTotvalue] = useState(0);
  const [totdiscount, setTotdiscount] = useState(0);
  const [totalorderval, setTotalorderval] = useState(0);

  // CUSTOM FOOTER COMPONENT
  const [misc1, setmisc1] = useState("");
  const [misc2, setmisc2] = useState("");
  const [value1, setvalue1] = useState(0);
  const [value2, setvalue2] = useState(0);
  const gst28 = griddataarray.filter((opt) => opt.gstpers === "28.00");
  const totgst28 = parseFloat(
    gst28.map((item) => parseFloat(item.gstamt)).reduce((a, b) => a + b, 0)
  ).toFixed(2);
  const gst18 = griddataarray.filter((opt) => opt.gstpers === "18.00");
  const totgst18 = parseFloat(
    gst18.map((item) => parseFloat(item.gstamt)).reduce((a, b) => a + b, 0)
  ).toFixed(2);
  const gst12 = griddataarray.filter((opt) => opt.gstpers === "12.00");
  const totgst12 = parseFloat(
    gst12.map((item) => parseFloat(item.gstamt)).reduce((a, b) => a + b, 0)
  ).toFixed(2);
  const gst5 = griddataarray.filter((opt) => opt.gstpers === "5.00");
  const totgst5 = parseFloat(
    gst5.map((item) => parseFloat(item.gstamt)).reduce((a, b) => a + b, 0)
  ).toFixed(2);

  const [emodegst28, setemodegst28] = useState(0);
  const [emodegst18, setemodegst18] = useState(0);
  const [emodegst12, setemodegst12] = useState(0);
  const [emodegst5, setemodegst5] = useState(0);

  /// TOTAL VALUE STATES FOR  HEADER TABLE

  const [newtotallossqty, setnewTotallossqty] = useState(0);
  const [newtotlossval, setnewTotlossval] = useState(0);
  const [newtotmiscamt, setnewTotmiscamt] = useState(0);
  const [newtotgstamt, setnewTotgstamt] = useState(0);
  const [newtotnetamt, setnewTotnetamt] = useState(0);
  const [newtotvalue, setnewTotvalue] = useState(0);
  const [newtotdiscount, setnewTotdiscount] = useState(0);
  const [newtotalorderval, setnewTotalorderval] = useState(0);
  ///EDIT MODE GRID STORE ARRAY

  const [emodedetails, setEmodeDetails] = useState([]);
  const editedDetails = Object.values(emodedetails);
  // console.log(editedDetails);
  // SUBMIT CUSTOMER ORDER DETAILS
  const handleCustomerOrderSubmit = async (e) => {
    e.preventDefault();
    setDisableSave(true);
    try {
      const body = {
        category,
        billsearch,
        orderdate,
        flags,
        griddataarray,
        totallossqty,
        totlossval,
        totdiscount,
        totgst12,
        totgst18,
        totgst28,
        totgst5,
        totgstamt,
        totmiscamt,
        totnetamt,
        totvalue,
        misc1,
        value1,
        misc2,
        value2,
        flagcode,
      };
      const response = await fetch("/customerorderin", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(`Order No ${parseRes.orderno} Created Successfully`, {
          autoClose: false,
          onClose: () => window.location.reload(false),
        });
      } else {
        toast.error(parseRes.message);
        setDisableSave(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  /// SUBMIT EDITMODE DETAILS

  const handleEditmodeSubmit = async (e) => {
    e.preventDefault();
    try {
      setDisableUpdate(true);
      const body = {
        editedDetails,
        emodegst12,
        emodegst18,
        emodegst28,
        emodegst5,
        newtotallossqty,
        newtotdiscount,
        newtotgstamt,
        newtotlossval,
        newtotmiscamt,
        newtotnetamt,
        newtotvalue,
        orderdate,
        category,
        flags,
        flagcode,
        misc1,
        value1,
        misc2,
        value2,
        partyValue,
      };
      const response = await fetch(
        `/corderupdate/${ordersearch.corderheaderid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(parseRes.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        toast.error(parseRes.message);
        setDisableUpdate(false);
      }
    } catch (err) {
      console.log(err.message);
      setDisableUpdate(false);
    }
  };

  //DISABLED STATES

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);

  // SOURCES FLAG  DROPDOWN VALUES AND HANDLER
  const [flagcode, setflagcode] = useState("OF");
  const [flags, setFlags] = useState(false);
  const [mode, setMode] = useState(1);
  const [disablecancelbtn, setdisablecancelbtn] = useState(true);

  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
    {
      value: 3,
      label: "View",
    },
  ];

  const Flags = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  // CATEGORY  DROPDOWN VALUES AND HANDLER
  const [category, setCategory] = useState("GEN");
  const Category = [
    {
      value: "VOR",
      label: "VOR",
    },
    {
      value: "GEN",
      label: "GEN",
    },
  ];

  //HANDLE PARTIES WHO HAVE ORDERED
  const [partyfetch, setpartyfetch] = useState(false);
  const [parties, setparties] = useState([]);
  async function getparties() {
    try {
      const response = await fetch("/partyorders", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsondata = await response.json();
      setparties(jsondata);
    } catch (err) {
      console.log(err.msg);
    }
  }
  async function getviewparties() {
    try {
      const response = await fetch("/view/partyorders", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsondata = await response.json();
      setparties(jsondata);
    } catch (err) {
      console.log(err.msg);
    }
  }
  useEffect(() => {
    if (parseInt(mode) === 2) {
      getparties();
    } else if (parseInt(mode) === 3) {
      getviewparties();
    } else {
      return;
    }
  }, [mode]);

  //----------- Modal Handler-------\\

  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  //CUSTOMER ORDER SEARCH
  const [ordersearch, setOrderSearch] = useState(null);
  //CLICKING ON ORDER SEARCH EXECUTES FOLLOWING \\
  const [emodearray, setemodearray] = useState([]);
  const handleOrderSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/corderdetails/${ordersearch.corderheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      setCategory(jsonData[0].ordercatg);
      setorderdate(jsonData[0].orderdate);
      setmisc1(jsonData[0].misc1_desc);
      setvalue1(jsonData[0].misc1_amt);
      setmisc2(jsonData[0].misc2_desc);
      setvalue2(jsonData[0].misc2_amt);
      setFlags(jsonData[0].onlineflag);
      setemodearray(jsonData);
      setdisablecancelbtn(false);
      if (jsonData[0].cancelflag === true) {
        setcanceldisplay("flex");
      } else {
        setcanceldisplay("none");
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [dopen, setdOpen] = useState(false);

  const handledClickOpen = () => {
    setdOpen(true);
  };

  const handledClose = () => {
    setdOpen(false);
  };

  const [missingArray, setMissingArray] = useState([]);
  const [partyValue, setPartyValue] = useState(null);
  const [disableAgree, setDisableAgree] = useState(false);
  const handlePartyHeader = async () => {
    try {
      const response = await fetch(`/partydata/${partyValue.subledgerid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      setpartyname(jsonData[0].subledgername);
      setaddress1(jsonData[0].address1);
      setaddress2(jsonData[0].address2);
      setaddress3(jsonData[0].address3);
      setsalesmanname(jsonData[0].salesmanname);
      setpanno(jsonData[0].panno);
      setgstno(jsonData[0].gstno);
      setdiscrate(jsonData[0].partsdiscrate);
      setspecialdis(jsonData[0].specialdis);
      setLubDiscRate(jsonData[0].lubdiscrate);
      setSyntheticDiscRate(jsonData[0].syntheticdiscrate);
      setSemiSyntheticDiscRate(jsonData[0].semisyntheticdiscrate);
      setRaceProDiscRate(jsonData[0].raceprodiscrate);
      setenginepartspia(jsonData[0].enginepartspia);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handlePartyHeader();
  }, [partyValue]);
  const handlePartyDetailRecalculate = () => {
    try {
      const newArray = emodearray.map((item) => {
        const newdiscrate = item.spcldiscount
          ? parseFloat(specialdis) + parseFloat(discrate)
          : parseFloat(discrate);
        const disamt = (parseFloat(newdiscrate) * parseFloat(item.value)) / 100;
        const taxable = parseFloat(item.value) - parseFloat(disamt);
        const gstamt = (parseFloat(item.gstpers) * parseFloat(taxable)) / 100;
        const netamt = parseFloat(gstamt) + parseFloat(taxable);
        return {
          ...item,
          dispers: parseFloat(newdiscrate).toFixed(2),
          disamt: parseFloat(disamt).toFixed(2),
          taxable: parseFloat(taxable).toFixed(2),
          gstamt: parseFloat(gstamt).toFixed(2),
          netamt: parseFloat(netamt).toFixed(2),
        };
      });

      setemodearray(newArray);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handlePartyDetailRecalculate();
  }, [partyValue, discrate]);

  //cancel order
  const handlecancel = async (e) => {
    e.preventDefault();
    try {
      setDisableAgree(true);
      const res = await fetch(`/corder/cancel/${ordersearch.corderheaderid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await res.json();
      if (data.type === "success") {
        toast.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      console.log(error.message);
      setDisableAgree(false);
    }
  };

  if (loading) {
    return <LoadingAnimation />;
  }
  return (
    <div>
      <Sidebar
        Title={"CUSTOMER ORDER"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack
              spacing={1}
              mb={"15px"}
              direction="row"
              justifyContent={"center"}
            >
              <Autocomplete
                disabled={searchdisabled}
                id="partyno"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={parties}
                getOptionLabel={(option) => option.parties}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: "500px",
                    }}
                    {...params}
                    size="small"
                    placeholder="Search Party"
                  />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack
              spacing={1}
              columnGap={2}
              mb={2}
              direction="row"
              mt={2}
              ml={4}
            >
              <TextField
                id="editmode"
                required
                select
                label="Edit Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (e.target.value === 2 || 3) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    setpartyfetch(true);
                  } else {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    setpartyfetch(false);
                  }
                }}
                // helperText="Select customer Edit mode"
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <PicklistView />
              <PickList />
              {parseInt(mode) === 1 ? (
                <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography fontSize={"14px"}>CODE WISE</Typography>
                    <Switch
                      onChange={handleMSwitchChange}
                      checked={!isMSwitchOn}
                      size="small"
                      inputProps={{ "aria-label": "ant design" }}
                    />
                    <Typography fontSize={"14px"}>NAME WISE</Typography>
                  </Stack>
                  <Autocomplete
                    id="partysearch"
                    value={billsearch}
                    filterOptions={filterOptionsParty}
                    onChange={(event, newValue) => {
                      setBillSearch(newValue);
                    }}
                    options={partyopts}
                    getOptionLabel={(option) =>
                      isMSwitchOn ? option.partydetails : option.partydetails2
                    }
                    renderInput={(partyopts) => (
                      <TextField
                        sx={{
                          width: 400,
                        }}
                        required
                        {...partyopts}
                        placeholder="Search Party"
                      />
                    )}
                    size="small"
                  />
                  <Button
                    size="small"
                    type="search"
                    variant="outlined"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#673AB7",
                      },
                      marginLeft: 50,
                      backgroundColor: "green",
                      borderRadius: "10px",
                    }}
                    onClick={handleBillSearch}
                  >
                    <BillSearch sx={{ color: "white" }} />
                  </Button>
                </Stack>
              ) : (
                <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                  <Autocomplete
                    id="Order Search"
                    value={ordersearch}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setOrderSearch(newValue);
                    }}
                    options={ordernos}
                    getOptionLabel={(option) => option.orderno}
                    renderInput={(ordernos) => (
                      <TextField
                        sx={{
                          width: 200,
                        }}
                        required
                        {...ordernos}
                        placeholder="Search Order"
                      />
                    )}
                    size="small"
                  />
                  <Button
                    size="small"
                    type="search"
                    variant="outlined"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#673AB7",
                      },
                      marginLeft: 50,
                      backgroundColor: "purple",
                      borderRadius: "10px",
                    }}
                    onClick={handleOrderSearch}
                  >
                    <BillSearch sx={{ color: "white" }} />
                  </Button>
                </Stack>
              )}
            </Stack>

            <Stack spacing={2} columnGap={1} direction="row" mt={1} ml={4}>
              {/* {parseInt(mode) === 1 ? (
                <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                  <Autocomplete
                    id="partysearch"
                    value={billsearch}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setBillSearch(newValue);
                    }}
                    options={partyopts}
                    getOptionLabel={(option) => option.partydetails}
                    sx={{
                      width: "85% ",
                    }}
                    renderInput={(partyopts) => (
                      <TextField
                        required
                        {...partyopts}
                        placeholder="Search Party"
                      />
                    )}
                    size="small"
                  />
                  <Button
                    size="small"
                    type="search"
                    variant="outlined"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#673AB7",
                      },
                      backgroundColor: "purple",
                      height: "100%",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                    onClick={handleBillSearch}
                  >
                    <BillSearch sx={{ color: "white" }} />
                  </Button>
                </Stack>
              ) : (
                <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                  <Autocomplete
                    id="Order Search"
                    value={ordersearch}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setOrderSearch(newValue);
                    }}
                    options={ordernos}
                    getOptionLabel={(option) => option.orderno}
                    sx={{
                      width: "85% ",
                    }}
                    renderInput={(ordernos) => (
                      <TextField
                        required
                        {...ordernos}
                        placeholder="Search Order"
                      />
                    )}
                    size="small"
                  />
                  <Button
                    size="small"
                    type="search"
                    variant="outlined"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#673AB7",
                      },
                      width: "120px",
                      marginBottom: "20px",
                      backgroundColor: "purple",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                    onClick={handleOrderSearch}
                  >
                    <BillSearch sx={{ color: "white" }} />
                  </Button>
                </Stack>
              )} */}
              <TextField
                id="category"
                required
                select
                label="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                // helperText="Select the Category ."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {Category.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={orderdate || null}
                  onChange={(newValue) => {
                    setorderdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                id="onlineflag"
                required
                select
                label="Online Order"
                value={flags}
                onChange={(e) => {
                  setFlags(e.target.value);
                  e.target.value === true
                    ? setflagcode("ON")
                    : setflagcode("OF");
                }}
                // helperText="Select customer's order mode ."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {Flags.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="partyost"
                label="Party Outstanding"
                value={outstandingdata}
                type="text"
                sx={{
                  width: 200,
                }}
                size="small"
              />
              {/* <Box display={mode === 1 ? "none" : "flex"}>
                <Autocomplete
                  id="partysearch"
                  value={billsearch}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setBillSearch(newValue);
                  }}
                  options={partyopts}
                  getOptionLabel={(option) => option.partydetails}
                  renderInput={(partyopts) => (
                    <TextField
                      sx={{
                        width: 150,
                      }}
                      required
                      {...partyopts}
                      placeholder="Party Name"
                    />
                  )}
                  size="small"
                />
              </Box> */}
            </Stack>
            <Stack spacing={2} columnGap={1} direction="row" mt={1} ml={4}>
              {parseInt(mode) === 1 ? (
                <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                  <TextField
                    id="partyname"
                    label="Partyname"
                    value={partyname}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      width: 250,
                    }}
                    size="small"
                  />
                </Stack>
              ) : (
                <Autocomplete
                  id="partysearch"
                  value={partyValue}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setPartyValue(newValue);
                  }}
                  options={partyopts}
                  getOptionLabel={(option) => option.partydetails}
                  renderInput={(partyopts) => (
                    <TextField
                      sx={{
                        width: 200,
                      }}
                      required
                      {...partyopts}
                      placeholder="Party Name"
                    />
                  )}
                  size="small"
                />
              )}
              <TextField
                id="salesmanName"
                label="Salesman"
                value={salesmanname}
                InputLabelProps={{ shrink: true }}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />

              <TextField
                id="address1"
                label="Address 1"
                value={address1}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address2"
                label="Address 2"
                value={address2}
                InputLabelProps={{ shrink: true }}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>

            <Stack
              spacing={2}
              columnGap={1}
              direction="row"
              mt={1}
              ml={4}
              mb={1}
            >
              <TextField
                id="address3"
                label="Address 3"
                value={address3}
                InputLabelProps={{ shrink: true }}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />

              <TextField
                id="panNo"
                label="Pan No"
                value={panno}
                InputLabelProps={{ shrink: true }}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="gstnum"
                label="GST No"
                value={gstno}
                type="text"
                sx={{
                  width: "20%",
                }}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
              <TextField
                id="discount"
                label="Discount%"
                value={discrate}
                InputLabelProps={{ shrink: true }}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>

            <Stack spacing={2} columnGap={6} direction="row" mt={1} mb={1}>
              <Accordion
                sx={{ width: "100%", backgroundColor: "#d0deec" }}
                // disabled={disableaccordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: "#a4c2e0" }}
                >
                  <Typography>Misc</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    spacing={2}
                    columnGap={6}
                    direction="row"
                    // ml={33}
                    mt={1}
                  >
                    <TextField
                      id="misc1"
                      label="Misc 1"
                      value={misc1}
                      onChange={(e) => setmisc1(e.target.value)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="value1"
                      label="Value 1"
                      value={value1}
                      onChange={(e) => setvalue1(e.target.value)}
                      type="number"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="misc2"
                      label="Misc 2"
                      value={misc2}
                      onChange={(e) => setmisc2(e.target.value)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="value2"
                      label="Value 2"
                      value={value2}
                      onChange={(e) => setvalue2(e.target.value)}
                      type="number"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Stack>

            <hr />
            <Stack columnGap={3} direction="row" mt={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Old Mode</Typography>
                <Switch
                  disabled={parseInt(mode) === 3}
                  onChange={handleSwitchChange}
                  checked={!isSwitchOn}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>New Mode</Typography>
              </Stack>
              <Button
                sx={{ ml: "3%", mt: "5px" }}
                disabled={parseInt(mode) === 3}
                variant="outlined"
                size="small"
                onClick={handleOpen}
                startIcon={<AddCircleOutline />}
              >
                ADD A NEW ROW
              </Button>
              {parseInt(mode) === 3 && (
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  display={canceldisplay}
                  color={"red"}
                  textAlign={"center"}
                >
                  THIS IS A CANCELED ORDER!!
                </Typography>
              )}
              {parseInt(mode) === 1 || parseInt(mode) === 2 ? (
                <>
                  {" "}
                  <ImportExcel
                    discrate={discrate}
                    lubdiscrate={lubdiscrate}
                    batterydiscrate={batterydiscrate}
                    syntheticdiscrate={syntheticdiscrate}
                    semisyntheticdiscrate={semisyntheticdiscrate}
                    raceprodiscrate={raceprodiscrate}
                    enginepartspia={enginepartspia}
                    specialdis={specialdis}
                    setrows={setrows}
                    mode={mode}
                    setMissingArray={setMissingArray}
                    partyname={partyname}
                  />
                </>
              ) : (
                <></>
              )}

              {missingArray.length > 0 && (
                <Box>
                  <Tooltip placement="top" title="Some Items Might be missing!">
                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="add"
                      style={{ fontSize: 1 }}
                      onClick={handledClickOpen}
                    >
                      <NotificationImportantIcon />
                    </Fab>
                  </Tooltip>
                </Box>
              )}

              <Draggable>
                <Modal
                  sx={{ mt: "5%" }}
                  open={open}
                  BackdropProps={{ invisible: true }}
                  aria-labelledby="modl-box"
                  aria-describedby="search partitem"
                >
                  <Box sx={style}>
                    <Box
                      sx={{
                        padding: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        columnGap: 1,
                        rowGap: 1,
                      }}
                    >
                      <Box display={isSwitchOn ? "flex" : "none"}>
                        <TextField
                          id="partno"
                          label="Part No"
                          value={newModePartNo}
                          onChange={(e) => setNewModePartNo(e.target.value)}
                          inputRef={newPartNoRef}
                          onKeyDown={handlePartnoKey}
                          sx={{
                            width: "200px",
                          }}
                          size="small"
                        />
                      </Box>
                      <Box display={!isSwitchOn ? "flex" : "none"}>
                        <Autocomplete
                          id="partno"
                          value={partno}
                          filterOptions={filterOptions2}
                          onChange={(event, newValue) => {
                            setpartno(newValue);
                            setmrp(newValue.mrp);
                            setstock(newValue.purchaseqty);
                            setDelItem(newValue.deleteditem);
                            setaltFlag(newValue.altflag);
                            //console.log(newValue);
                          }}
                          options={allparts}
                          getOptionLabel={(option) => option.partdetails}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                width: 250,
                              }}
                              // inputRef={altItemRef}
                              // onKeyDown={handleAltShortcut}
                              placeholder="Part No"
                              variant="outlined"
                              size="small"
                              inputRef={partnoRef}
                            />
                          )}
                        />
                      </Box>
                      <TextField
                        id="quantity"
                        label="Quantity"
                        value={itemqty}
                        onChange={(e) => setitemqty(e.target.value)}
                        inputRef={quantityRef}
                        onKeyDown={handleKeyDown}
                        type="number"
                        sx={{
                          width: "100px",
                        }}
                        size="small"
                      />
                      <TextField
                        id="stock"
                        label="Stock"
                        value={stock}
                        type="number"
                        sx={{
                          width: "100px",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <TextField
                        id="delitem"
                        label="Deleted Item"
                        value={delItem}
                        sx={{
                          width: "100px",
                          backgroundColor:
                            delItem && altFlag
                              ? "#ffff66"
                              : delItem && !altFlag
                              ? "#ff6666"
                              : "",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <TextField
                        id="altitem"
                        label="Alternate Item"
                        value={altFlag}
                        sx={{
                          width: "100px",
                          backgroundColor:
                            delItem && altFlag
                              ? "lightgreen"
                              : !delItem && altFlag
                              ? "lightgreen"
                              : "white",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <TextField
                        id="netamount"
                        label="Net Amount"
                        value={parseInt(mode) === 1 ? totnetamt : newtotnetamt}
                        sx={{
                          width: "100px",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <Button
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 80,
                          margin: 1, // Optional: Adjust margin as needed
                        }}
                        aria-label="add"
                        variant="contained"
                        size="small"
                        color="success"
                        tabIndex={-1}
                        onClick={handleaddrow}
                      >
                        ADD
                        {/* <AddBoxIcon /> */}
                      </Button>
                      <Button
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          margin: 1, // Optional: Adjust margin as needed
                        }}
                        aria-label="cancel"
                        variant="contained"
                        size="small"
                        color="error"
                        tabIndex={-1}
                        onClick={handleClose}
                      >
                        CANCEL
                        {/* <CancelIcon /> */}
                      </Button>

                      <Stack direction="row" spacing={2}>
                        <Table
                          sx={{
                            minWidth: 650,
                            boxShadow: "2px 6px 15px #5a5a5a",
                          }}
                          size="small"
                          aria-label="simple table"
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#3bb9ff",
                              border: "0.5px solid black",
                              height: 10,
                            }}
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                                align="center"
                              >
                                Alt-Item Name
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                                align="center"
                              >
                                Alt-Item Number
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                                align="center"
                              >
                                Alt-Item Stock
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                                align="center"
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {altparts.length > 0 ? (
                            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                              {altparts.map((opt) => (
                                <TableRow
                                  sx={{ height: 2 }}
                                  key={opt.alternateitemid}
                                >
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    {opt.itemname}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    {opt.itemcode}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    {opt.closingqty}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      onClick={(e) => handleSwitch(opt, e)}
                                    >
                                      Switch
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : (
                            <Typography variant="subtitle1" ml={10}>
                              NO ITEMS FOUND
                            </Typography>
                          )}
                        </Table>
                        <Stack direction="column" columnGap={1} spacing={2}>
                          <TextField
                            id="mrp"
                            label="MRP"
                            value={mrp}
                            //onChange={(e) => setitemqty(e.target.value)}
                            //inputRef={quantityRef}
                            //onKeyDown={handleKeyDown}
                            type="number"
                            sx={{
                              width: "100px",
                            }}
                            size="small"
                          />
                          <TextField
                            id="itemname"
                            label="Item Name"
                            value={itemname}
                            // onChange={(e) => setitemqty(e.target.value)}
                            // inputRef={quantityRef}
                            // onKeyDown={handleKeyDown}
                            //type="number"
                            sx={{
                              width: 300,
                            }}
                            size="small"
                          />
                        </Stack>
                      </Stack>
                      {/* <Stack columnGap={1} direction="row" mb={1} ml={"38%"}>
                      <Button
                        variant="contained"
                        tabIndex={-1}
                        size="medium"
                        color="success"
                        onClick={handleaddrow}
                      >
                        ADD
                      </Button>
                      <Button
                        tabIndex={-1}
                        variant="contained"
                        size="medium"
                        onClick={handleClose}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Stack> */}
                    </Box>
                  </Box>
                </Modal>
              </Draggable>
            </Stack>
            {parseInt(mode) === 1 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "15px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13, // Adjust the font size as needed
                  },
                }}
              >
                <DataGrid
                  sx={{
                    border: "1px solid #000",
                    "& .MuiDataGrid-cell:focus": {
                      outline: "2px solid red", // Change this to the desired color
                      backgroundColor: "gray",
                      color: "white",
                    },
                  }}
                  rowHeight={35}
                  headerHeight={30}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "serial", sort: "desc" }],
                    },
                  }}
                  showColumnRightBorder={true}
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  getRowId={(row) => row.itemid}
                  onStateChange={(params) => {
                    setgriddata(params.rows.idRowsLookup);
                    const losqtytot = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.losqty))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotallossqty(losqtytot);

                    const losqtyval = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.losval))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotlossval(losqtyval);

                    const newval = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.value))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotvalue(newval);

                    const newdisamt = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.disamt))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotdiscount(newdisamt);

                    const newgstamt = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.gstamt))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotgstamt(newgstamt);

                    const newnetamt = griddataarray
                      .map((item) => parseFloat(item.netamt))
                      .reduce((a, b) => a + b, 0);
                    setTotnetamt(
                      parseFloat(
                        parseFloat(newnetamt) +
                          parseFloat(value1) +
                          parseFloat(value2)
                      ).toFixed(2)
                    );
                    const neworderval = parseFloat(
                      parseFloat(totnetamt) + parseFloat(totlossval)
                    ).toFixed(2);
                    setTotalorderval(neworderval);
                  }}
                />
                <Box sx={{ padding: "10px", height: 170 }}>
                  <Stack
                    spacing={2}
                    columnGap={6}
                    direction="row"
                    ml={4}
                    mt={2}
                  >
                    <TextField
                      id="gst5"
                      label="GST @ 5%"
                      value={totgst5}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst18"
                      label="GST @ 18%"
                      value={totgst18}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst12"
                      label="GST @ 12%"
                      value={totgst12}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst28"
                      label="GST @ 28%"
                      value={totgst28}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  </Stack>
                  <Stack direction="row" mt={2}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Sale Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Dis Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            GST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Mis Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Net Sale Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Order Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Loss Qty
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Loss Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totvalue}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totdiscount}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totgstamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {parseFloat(value1) + parseFloat(value2)}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {" "}
                            {totnetamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totalorderval}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totallossqty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {" "}
                            {totlossval}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Stack>
                </Box>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  mx={"42%"}
                >
                  <Button
                    type="submit"
                    onClick={handleCustomerOrderSubmit}
                    disabled={totnetamt > 0 ? disableSave : true}
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    SAVE ORDER
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            {parseInt(mode) === 2 || parseInt(mode) === 3 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13,
                    //fontWeight:'bold'
                  },
                }}
              >
                <DataGrid
                  sx={{
                    border: "1px solid #000",
                    "& .MuiDataGrid-cell:focus": {
                      outline: "2px solid red", // Change this to the desired color
                      backgroundColor: "gray",
                      color: "white",
                    },
                  }}
                  rowHeight={35}
                  headerHeight={30}
                  showColumnRightBorder={true}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "serial", sort: "desc" }],
                    },
                  }}
                  rows={emodearray}
                  columns={columns}
                  pageSize={100}
                  getRowId={(row) => row.itemid}
                  onStateChange={(params) => {
                    setEmodeDetails(params.rows.idRowsLookup);
                    const losqtytot = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.losqty))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotallossqty(losqtytot);

                    const losqtyval = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.losval))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotlossval(losqtyval);

                    const newval = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.value))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotvalue(newval);

                    const newdisamt = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.disamt))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotdiscount(newdisamt);

                    const newgstamt = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.gstamt))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotgstamt(newgstamt);

                    const newnetamt = editedDetails
                      .map((item) => parseFloat(item.netamt))
                      .reduce((a, b) => a + b, 0);
                    setnewTotnetamt(
                      parseFloat(
                        parseFloat(newnetamt) +
                          parseFloat(value1) +
                          parseFloat(value2)
                      ).toFixed(2)
                    );
                    const newordval = parseFloat(
                      parseFloat(newtotnetamt) + parseFloat(newtotlossval)
                    ).toFixed(2);
                    setnewTotalorderval(newordval);
                    const newgst28 = editedDetails.filter(
                      (opt) => opt.gstpers === "28.00"
                    );
                    const newtotgst28 = newgst28
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setemodegst28(newtotgst28);
                    const newgst18 = editedDetails.filter(
                      (opt) => opt.gstpers === "18.00"
                    );
                    const newtotgst18 = newgst18
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setemodegst18(newtotgst18);
                    const newgst12 = editedDetails.filter(
                      (opt) => opt.gstpers === "12.00"
                    );
                    const newtotgst12 = newgst12
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setemodegst12(newtotgst12);
                    const newgst5 = editedDetails.filter(
                      (opt) => opt.gstpers === "5.00"
                    );
                    const newtotgst5 = newgst5
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setemodegst5(newtotgst5);
                  }}
                />
                <Box sx={{ padding: "10px", height: 170 }}>
                  <Stack
                    spacing={2}
                    columnGap={6}
                    direction="row"
                    ml={4}
                    mt={2}
                  >
                    <TextField
                      id="gst5"
                      label="GST @ 5%"
                      value={parseFloat(emodegst5).toFixed(2)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst18"
                      label="GST @ 18%"
                      value={parseFloat(emodegst18).toFixed(2)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst12"
                      label="GST @ 12%"
                      value={parseFloat(emodegst12).toFixed(2)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst28"
                      label="GST @ 28%"
                      value={parseFloat(emodegst28).toFixed(2)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  </Stack>
                  <Stack direction="row" mt={2}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Sale Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Dis Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            GST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Mis Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Net Sale Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Order Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Loss Qty
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Loss Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotvalue}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotdiscount}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotgstamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {parseFloat(value1) + parseFloat(value2)}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {" "}
                            {newtotnetamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotalorderval}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotallossqty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {" "}
                            {newtotlossval}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Stack>
                </Box>
                {parseInt(mode) === 2 ? (
                  <>
                    <Stack
                      spacing={10}
                      columnGap={2}
                      direction="row"
                      mt={6}
                      pb={6}
                      justifyContent={"center"}
                    >
                      <Button
                        type="submit"
                        onClick={handleEditmodeSubmit}
                        disabled={disableUpdate}
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ boxShadow: "2px 2px 6px  #404142" }}
                      >
                        UPDATE ORDER
                      </Button>

                      <Button
                        disabled={disablecancelbtn}
                        //type="submit"
                        onClick={handledalertOpen}
                        variant="contained"
                        color="error"
                        size="large"
                        // sx={{ boxShadow: "2px 2px 6px  #404142" }}
                      >
                        CANCEL ORDER
                      </Button>
                    </Stack>
                  </>
                ) : (
                  <></>
                )}
                {/* <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  mx={"42%"}
                >
                  <Button
                    type="submit"
                    onClick={handleEditmodeSubmit}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    UPDATE ORDER
                  </Button>
                </Stack> */}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        }
      />
      <Dialog
        open={dopen}
        onClose={handledClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Part nos"}</DialogTitle>
        <DialogContent>
          <Stack direction="row">
            <Table
              stickyHeader
              sx={{
                minWidth: 400,
                boxShadow: "2px 6px 15px #5a5a5a",
                height: 200,
              }}
              size="small"
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  backgroundColor: "#3bb9ff",
                  border: "0.5px solid black",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "#000", border: "0.5px solid black" }}
                    align="center"
                  >
                    Srl No.
                  </TableCell>
                  <TableCell
                    sx={{ color: "#000", border: "0.5px solid black" }}
                    align="center"
                  >
                    Part Nos
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                {missingArray.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {item}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={handledClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={alertopen}
        onClose={handledalertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to continue?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to cancel order no `}
            <strong>{ordersearch?.orderno}?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlecancel}
            disabled={disableAgree}
            color="success"
            variant="outlined"
          >
            Agree
          </Button>
          <Button onClick={handledalertClose} color="error" variant="outlined">
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default CustomerOrder;
